import React, { useEffect } from "react";

import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import { motion } from "framer-motion";
import InView from "react-intersection-observer";
import HTMLReactParser from "html-react-parser";

import Contact from "components/contact";
import { Office, TailorMade, Truck } from "components/icons";

const IndexPage = (props) => {
  const { dimensions, data } = props;

  const tl = gsap.timeline();

  useEffect(() => {
    tl.to(".banner h1", {
      opacity: 1,
      duration: 0.25,
      delay: dimensions.width < 1024 ? 0 : 1,
    })
      .to("#header", {
        opacity: 1,
        y: 0,
        duration: 0.25,
        delay: dimensions.width < 1024 ? 0 : -0.25,
      })
      .to(".example--2", {
        opacity: 0.6,
        duration: 0.25,
        delay: dimensions.width < 1024 ? 0 : -0.25,
      });
  }, []);

  return (
    <>
      <section className="banner">
        {dimensions.width / dimensions.height > 1 && (
          <StaticImage
            src="../../images/bg3.jpg"
            alt="Maderas apiladas"
            layout="constrained"
            height={1080}
          />
        )}
        {dimensions.width / dimensions.height < 1 &&
          dimensions.width <= 1023 &&
          dimensions.height < 768 && (
            <StaticImage
              src="../../images/bg_m.jpg"
              alt="Maderas apiladas"
              layout="fixed"
              height={1080}
            />
          )}
        {dimensions.width / dimensions.height < 1 &&
          dimensions.width <= 1023 &&
          dimensions.height >= 768 && (
            <StaticImage
              src="../../images/bg_m.jpg"
              alt="Maderas apiladas"
              layout="fixed"
              height={1300}
            />
          )}
        <div className="container">
          <h1>{data.banner.intro}</h1>
          <div className="example example--2">
            <span className="scroll-icon">
              <span className="scroll-icon__dot"></span>
            </span>
          </div>
        </div>
      </section>
      <section className="aboutus container" id="aboutus">
        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                initial={{ opacity: 0, y: 30 }}
                transition={{ duration: 0.5 }}
                className="text-wrapper"
              >
                <h1 className="h3">{data.aboutus.h3}</h1>
                <h3 className="h1">{data.aboutus.h1}</h3>
                {HTMLReactParser(data.aboutus.text)}
              </motion.div>
            );
          }}
        </InView>
        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                initial={{ opacity: 0, y: 30 }}
                transition={{
                  duration: 0.5,
                  delay: dimensions.width < 1024 ? 0 : 0.3,
                }}
                className="image-wrapper"
              >
                <StaticImage
                  src="../../images/amb.png"
                  alt="Render 3D de una habitación"
                  layout="constrained"
                  height={1080}
                />
              </motion.div>
            );
          }}
        </InView>
      </section>
      <section className="services" id="services">
        <div className="container">
          <InView threshold={0.4} triggerOnce>
            {({ inView, ref, entry }) => {
              return (
                <motion.div
                  ref={ref}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }
                  }
                  initial={{ opacity: 0, y: 30 }}
                  transition={{ duration: 0.5 }}
                >
                  <h1 className="h3">{data.services.h3}</h1>
                  <h3 className="h1">{data.services.h1}</h3>
                </motion.div>
              );
            }}
          </InView>
          <div className="services-wrapper">
            <InView threshold={0.4} triggerOnce>
              {({ inView, ref, entry }) => {
                return (
                  <motion.div
                    ref={ref}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }
                    }
                    initial={{ opacity: 0, y: 30 }}
                    transition={{
                      duration: 0.5,
                      delay: dimensions.width < 1024 ? 0 : 0.3,
                    }}
                    className="services-wrapper--box"
                  >
                    <h2>{data.services.tailor_h1}</h2>
                    {HTMLReactParser(data.services.tailor_text)}
                    <TailorMade color="white" />
                  </motion.div>
                );
              }}
            </InView>
            <InView threshold={0.4} triggerOnce>
              {({ inView, ref, entry }) => {
                return (
                  <motion.div
                    ref={ref}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }
                    }
                    initial={{ opacity: 0, y: 30 }}
                    transition={{
                      duration: 0.5,
                      delay: dimensions.width < 1024 ? 0 : 0.45,
                    }}
                    className="services-wrapper--box"
                  >
                    <h2>{data.services.contract_h1}</h2>
                    {HTMLReactParser(data.services.contract_text)}
                    <Office color="white" />
                  </motion.div>
                );
              }}
            </InView>
            <InView threshold={0.4} triggerOnce>
              {({ inView, ref, entry }) => {
                return (
                  <motion.div
                    ref={ref}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }
                    }
                    initial={{ opacity: 0, y: 30 }}
                    transition={{
                      duration: 0.5,
                      delay: dimensions.width < 1024 ? 0 : 0.6,
                    }}
                    className="services-wrapper--box"
                  >
                    <h2>{data.services.delivery_h1}</h2>
                    {HTMLReactParser(data.services.delivery_text)}
                    <Truck color="white" />
                  </motion.div>
                );
              }}
            </InView>
          </div>
        </div>
      </section>
      <section className="production container" id="production">
        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                initial={{ opacity: 0, y: 30 }}
                transition={{ duration: 0.5 }}
                className="image-wrapper"
              >
                <StaticImage
                  src="../../images/fab1.jpg"
                  alt="Trabajador en la fábrica de Adalmáris"
                  layout="constrained"
                  height={1080}
                  className="image1"
                />

                <StaticImage
                  src="../../images/fab2.jpg"
                  alt="Trabajador en la fábrica de Adalmáris"
                  layout="constrained"
                  height={1080}
                  className="image2"
                />
              </motion.div>
            );
          }}
        </InView>
        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                initial={{ opacity: 0, y: 30 }}
                transition={{
                  duration: 0.5,
                  delay: dimensions.width < 1024 ? 0 : 0.3,
                }}
                className="text-wrapper"
              >
                <h1 className="h3">{data.own.h3}</h1>
                <h3 className="h1">{data.own.h1}</h3>
                {HTMLReactParser(data.own.text)}
              </motion.div>
            );
          }}
        </InView>
      </section>
      <section className="container showroom" id="showroom">
        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                initial={{ opacity: 0, y: 30 }}
                transition={{ duration: 0.5 }}
              >
                <h1 className="h3">{data.showroom.h3}</h1>
                <h3 className="h1">{data.showroom.h1}</h3>
              </motion.div>
            );
          }}
        </InView>
        <div className="showroom--wrapper">
          <InView threshold={0.4} triggerOnce>
            {({ inView, ref, entry }) => {
              return (
                <motion.div
                  ref={ref}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }
                  }
                  initial={{ opacity: 0, y: 30 }}
                  transition={{
                    duration: 0.5,
                    delay: dimensions.width < 1024 ? 0 : 0.3,
                  }}
                >
                  <StaticImage src="../../images/show1.jpg" alt="Showroom" />
                </motion.div>
              );
            }}
          </InView>
          <InView threshold={0.4} triggerOnce>
            {({ inView, ref, entry }) => {
              return (
                <motion.div
                  ref={ref}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }
                  }
                  initial={{ opacity: 0, y: 30 }}
                  transition={{
                    duration: 0.5,
                    delay: dimensions.width < 1024 ? 0 : 0.45,
                  }}
                >
                  <StaticImage src="../../images/show2.jpg" alt="Showroom" />
                </motion.div>
              );
            }}
          </InView>
          <InView threshold={0.4} triggerOnce>
            {({ inView, ref, entry }) => {
              return (
                <motion.div
                  ref={ref}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }
                  }
                  initial={{ opacity: 0, y: 30 }}
                  transition={{
                    duration: 0.5,
                    delay: dimensions.width < 1024 ? 0 : 0.6,
                  }}
                >
                  <StaticImage src="../../images/show3.jpg" alt="Showroom" />
                </motion.div>
              );
            }}
          </InView>
          <InView threshold={0.4} triggerOnce>
            {({ inView, ref, entry }) => {
              return (
                <motion.div
                  ref={ref}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }
                  }
                  initial={{ opacity: 0, y: 30 }}
                  transition={{
                    duration: 0.5,
                    delay: dimensions.width < 1024 ? 0 : 0.6,
                  }}
                >
                  <a href="/catalogo-pt.pdf" target="_blank">
                    Ver Catálogo
                  </a>
                </motion.div>
              );
            }}
          </InView>
        </div>
      </section>

      <section className="container contact" id="contact">
        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                initial={{ opacity: 0, y: 30 }}
                transition={{ duration: 0.5 }}
                className="contact-form"
              >
                <h1 className="h3">{data.contacts.h3}</h1>
                <h3 className="h1">{data.contacts.h1}</h3>
                <Contact data={data.contacts.form} isPt={true} />
              </motion.div>
            );
          }}
        </InView>
        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                initial={{ opacity: 0, y: 30 }}
                transition={{ duration: 0.5 }}
                className="contact-map"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.2060192074905!2d-8.341885184576784!3d41.30438157927184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24f2ce708fd91f%3A0x255b058ccb77da18!2sADALMARIS%2C%20LDA!5e0!3m2!1spt-PT!2spt!4v1617144542760!5m2!1spt-PT!2spt"
                  width="100% "
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  title="Google Map da fábrica da Adalmaris"
                ></iframe>
              </motion.div>
            );
          }}
        </InView>
      </section>
    </>
  );
};

export default IndexPage;
